import React from "react";
import Col from "react-bootstrap/Col";
import GuideCard from "./GuideCard";
import { useStaticQuery, graphql } from "gatsby";

function getImageSharp(allImages, relativePath) {
  const ImageEdge = allImages.images.edges.find(edge => edge.node.relativePath === relativePath);
  return (ImageEdge === undefined) ? null : ImageEdge.node.childImageSharp;
}

export default ({ guideListData }) => {
  const allImages = useStaticQuery(graphql`
  query guideImagesQuery {
      images: allFile(filter: {relativeDirectory: {eq: "img"}, extension: {in: ["jpg", "jpeg", "png"]}}) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
            relativePath
          }
        }
      }
    }
  `)
  let dataArray = [];
  let guideCardData = {};
  let listItems = null;
  if (Array.isArray(guideListData) && guideListData.length > 0) {
    guideListData.forEach((guide) => {
      guideCardData = {
        title: guide.title,
        phoneNumber: guide.phoneNumber,
        email: guide.email,
        website: guide.website,
        imageSharp: getImageSharp(allImages, guide.profilImagePath)
      };
      dataArray.push(guideCardData);
    });
    listItems = dataArray.map((data, index) => {
      return (
        <Col key={`guide-${index}`} xs={12} md={6} lg={4} xl={3}>
          <GuideCard {...data} />
        </Col>
      );
    });
  }
  return (
    <React.Fragment>
      {listItems}
    </React.Fragment>
  );
}